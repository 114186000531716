/* Importar Colores y Fuentes */
@import 'colors';
@import 'fonts';

/* Importar Estilos de los Componentes*/
@import '../components/Layout/layout.scss';
@import '../components/Navigation/navigation.scss';
@import '../components/Home/home.scss';
@import '../components/Session/session.scss';
@import '../components/PortabilityConsumeAppForm/portabilityconsume.scss';
@import '../components/Profile/profile.scss';
@import '../components/MortgageApplicationForm/ApplicationForm.scss';

/* ESTILOS PARA LOS ARTÍCULOS MOSTRADOS LOCALMENTE. ACTUALMENTE EN
PRODUCCIÓN CADA ARTÍCULO TIENE SU PROPIO ENLACE.*/
@import '../components/Articles/articles.scss';

/* Definir Estilos Generales */
#app-wrapper {
  width: 100%;
}

.small-button.ant-btn {
  border-radius: 4px;
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  background-color: $primary-color;
  border-color: $primary-color;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 3px;
}

.big-button.ant-btn {
  background-color: $light-blue;
  border-color: $light-blue;
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: bold;
  height: 60px;
  width: 280px;
  letter-spacing: 4px;
}

.white-button.ant-btn {
  text-transform: capitalize;
  border-color: $primary-color;
  color: $primary-color;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Roboto';

  @media screen and (min-width: 768px) {
    padding: 4px 32px;
  }
}

.primary-button.ant-btn {
  text-transform: capitalize;
  background: linear-gradient(to right, #3378d6, #4292ff);
  border-color: $light-blue;
  color: $white;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Roboto';

  @media screen and (min-width: 768px) {
    padding: 4px 32px;
  }
}

.primary-button.ant-btn:hover {
  background-color: $primary-color;
  color: $white;
}

.flex-end {
  div {
    display: flex;
    justify-content: flex-end;
  }
}

.flex-center {
  display: flex;
  text-align: center;
  justify-content: center;
}

.ant-result {
  padding: 16px !important;
}

.clickable {
  cursor: pointer;
}

.ant-modal-content {
  border-radius: 8px !important;
}

.link {
  color: $light-blue !important;
  cursor: pointer !important;
  width: fit-content;
  margin-top: 8px;

  span {
    margin-left: 4px;
  }
}

// Usado en el componente de ProfilPersonalInfo
.editable-field span {
  position: relative;

  .anticon {
    position: static;
  }

  .edit-icon > svg,
  svg.edit-icon {
    position: absolute;
    background-color: rgba(5, 3, 88, 0.1);
    border-radius: 50%;
    overflow: visible;
    cursor: pointer;
    padding: 8px;
    height: auto;
    width: auto;
    left: 265px;
    bottom: 12px;

    @media screen and (min-width: 768px) {
      left: 280px;
    }
  }

  .ant-select-arrow {
    position: absolute !important;
    right: 20px;
  }

  .ant-form-item-control-input {
    min-width: 245px;
    width: auto;
  }
}

// Estilo de ícono de información de mensaje en Formulario de Información
// de Crédito de Cotizaciones de Consumo, en las acciones del Listado de Cotizaciones
// y el que aparece sobre el Chat cuando se elige ver una cotización
.info-icon-style {
  svg {
    color: $magenta;
    height: 15px !important;
    width: 15px !important;
  }
}
