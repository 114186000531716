#profile-application-form {
  .ant-form-vertical {
    padding: 0 10px;
  }

  @media screen and (min-width: 768px) {
    .ant-form-vertical {
      padding: 0 36px;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  #application-form-steps {
    align-items: center;
    margin-top: 10px;

    @media screen and (min-width: 768px) {
      margin-top: auto;
      padding: 0 24px;
    }

    .ant-steps-item {
      width: 70%;

      @media screen and (min-width: 768px) {
        width: auto;
      }
    }

    .ant-steps-item-title {
      font-size: 13px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }

    .ant-steps-item-active {
      .ant-steps-item-icon {
        background: $light-blue;
        border-color: $light-blue;
      }

      .ant-steps-item-title {
        color: $primary-color;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        border-color: $light-blue;
      }

      svg {
        fill: $light-blue;
      }
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;

    label {
      font-family: 'Roboto';
      color: $primary-color;
      font-size: 12px;
    }
  }

  .ant-form-item-explain {
    font-size: 10px;
  }

  .ant-form-item-has-error {
    .ant-input,
    .ant-input-number {
      border-color: rgb(255, 77, 79);
    }
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selector {
    background-color: $extra-light-gray;
    border-color: $gray;
    border-radius: 4px;
    width: 100%;
  }

  span.ant-select-arrow {
    width: auto;
  }

  input,
  span.ant-select-selection-item {
    font-family: 'Roboto';
    color: $primary-color;
  }

  .application-form-map-wrapper {
    height: 180px;
    margin-bottom: 24px;
  }

  .ant-slider {
    .ant-slider-rail {
      background-color: $gray;
    }

    .ant-slider-track,
    .ant-slider-handle {
      background-color: $magenta;
      border-color: $magenta;
    }

    .ant-slider-dot {
      border-color: #8281ac;
    }

    .ant-slider-dot-active {
      background-color: $magenta;
    }

    .ant-slider-mark {
      span {
        font-size: 10px;
        font-family: 'Roboto';
        font-weight: 900;
        letter-spacing: 0.1px;
      }

      span:nth-child(2) {
        font-size: 14px;
        color: $magenta;
      }
    }
  }

  .application-form-general-summary {
    padding: 12px 0;

    @media screen and (min-width: 768px) {
      padding: 24px 0 12px 24px;
    }

    .application-form-progress-text {
      display: flex;
      flex-direction: column;
      position: absolute;
      text-align: center;
      font-size: 15px;
      top: 60px;
      left: 14px;

      @media screen and (min-width: 768px) {
        left: 50px;
        font-size: 24px;
      }

      span {
        color: $primary-color;
        font-family: 'Roboto';
      }

      span:last-child {
        font-size: 12px;
      }
    }

    .application-form-general-legend {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .legend {
        display: flex;
        align-items: center;

        .dot {
          background-color: $primary-color;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          margin-right: 12px;
        }

        .title,
        .subtitle {
          font-family: 'Roboto';
          color: $primary-color;
        }

        .subtitle {
          font-weight: bold;
          font-size: 16px;
          margin-top: -4px;
        }
      }

      .legend:nth-child(2) .dot {
        background-color: #ebebeb;
      }
      .legend:nth-child(3) .dot {
        background-color: #1ca9e9;
      }
    }
  }

  .application-form-figure {
    display: flex;
    flex-direction: column;

    span {
      font-family: 'Roboto';
      color: $primary-color;
      font-size: 15px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }

    span:last-child {
      margin-top: -6px;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .right {
    align-items: flex-end;
  }

  .application-form-button {
    margin: 12px auto;
    background-color: $light-blue;
    color: $white;
    font-family: 'Roboto';
    border-radius: 8px;
    font-weight: 500;
    border-color: $light-blue;
    font-size: 16px;
    width: 100%;
    height: 42px;

    @media screen and (min-width: 768px) {
      padding: 0 28px;
    }
  }

  .application-form-button-secondary {
    background-color: $white;
    color: $light-blue;
    border-radius: 8px;
    font-weight: 500;
    border-color: $light-blue;
    font-size: 16px;
  }

  .magenta {
    background-color: $magenta;
    border-color: $magenta;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 50%;
      font-family: 'Roboto Condensed';
      font-weight: bold;
      color: $gray;
      text-align: center;
    }

    .ant-radio-button-wrapper-checked {
      color: $light-blue;
      border-color: $light-blue;
      background-color: rgba(66, 146, 255, 0.3);
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: $light-blue;
    }
  }

  h3 {
    padding-left: 0;
    font-weight: bold;
  }

  .ant-upload-drag:hover {
    border-color: $light-blue;
  }

  .application-form-upload-row {
    // .ant-col {
    //   display: flex;
    //   justify-content: center;
    // }

    margin-top: 16px;

    .ant-form-item {
      width: 100%;
    }

    .ant-form-item-control-input-content,
    .ant-form-item-label {
      display: flex;
      justify-content: center;
      margin-bottom: 4px;
    }

    .ant-form-item-control-input-content > span {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .ant-upload-select {
      button {
        background-color: $light-blue;
        font-family: 'Roboto';
        color: $white;
        border-radius: 4px;
        border-width: 0;
      }
    }

    .ant-upload-list {
      width: 100%;
    }
  }

  .small-letter {
    font-size: 10px;
    font-family: 'Roboto';
    color: $primary-color;
    margin: 16px 0;
  }

  .ant-row .ant-row .ant-row {
    margin-bottom: 0;
  }

  .anticon-question-circle {
    margin-left: 4px;
    margin-right: 16px;
  }
}

.application-result-modal {
  .ant-modal-content .ant-result {
    .ant-btn {
      border-radius: 8px;
      background-color: $light-blue;
      border-color: $light-blue;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      height: 40px;
    }
  }
}
