/* Tonos */
$primary-color: #050358;
$light-blue: #4292ff;
$dark-blue: #01003a;
$magenta-blue: #8884d8;
$azulino: #027aff;

$magenta: #e81687;

$gray: #abb8c3;
$dark-gray: #7d7d7d;
$light-gray: #dde8f1;
$extra-light-gray: #f5f8fa;

$white: #ffffff;
$white-smoke: #f5f5f5;

$light-green: #ddeeee;
