/* Contenedor Principal*/
#profile-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
  }
  max-width: 1200px;
}

/* Sección Perfil (Cuadro Lado izquierdo, Inicio, Mi Perfil, Cotizaciones, Cerrar Sesión) */
ul.ant-menu.profile-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-color;
  margin-bottom: 20px;
  padding: 12px 0;
  text-align: center;

  @media screen and (min-width: 992px) {
    flex-direction: column;
    width: 180px;
    border-right-width: 0;
    padding: 12px 4px;
    margin-bottom: 0;
    background-color: $white;
    text-align: start;
  }

  svg {
    margin-left: 8px;
    height: 20px;
    width: 20px;
    margin-bottom: 10px;

    @media screen and (min-width: 992px) {
      height: 16px;
      width: 16px;
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  li.ant-menu-item {
    font-weight: bold;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    font-family: 'Roboto';
    color: $gray;
    height: 80px;
    line-height: 25px;

    @media screen and (min-width: 992px) {
      font-size: 14px;
      flex-direction: row;
      height: auto;
      margin: 4px 0 !important;
      padding-left: 16px !important;
      white-space: nowrap;
      line-height: 40px;

      span {
        margin-bottom: 4px;
      }

      p {
        margin: 0 4px;
      }
    }
  }

  .ant-divider {
    min-width: 80%;
    width: 80%;
    margin: 12px auto;
  }

  li.ant-menu-item.ant-menu-item-selected {
    color: $white;
    background-color: transparent;

    @media screen and (min-width: 992px) {
      color: $primary-color;
      background-color: $extra-light-gray;
    }

    .magenta {
      color: $magenta;
      font-weight: bold;
    }
  }

  li.ant-menu-item::after {
    display: none;
  }
}

/* Sección de Información, cuadro derecho de la aplicación (tabla) */
#profile-content-wrapper {
  .profile-box-phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    padding-left: 24px;
  }

  .ant-divider {
    margin-top: 0;
    background-color: $gray;
  }

  .ant-layout-content {
    background-color: $white;
    padding: 12px 10px;

    @media screen and (min-width: 992px) {
      padding: 12px 24px;
    }
  }

  h1 {
    font-size: 20px;
    font-family: 'Roboto Slab';
    font-weight: bold;
    color: $primary-color;

    @media screen and (min-width: 992px) {
      font-size: 24px;
      padding-left: 12px;
    }
  }

  h3 {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: normal;
    color: $primary-color;
    padding-left: 12px;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 768px) {
      padding: 12px 0;
      align-items: flex-start;
    }

    .table-header-text {
      display: flex;
      align-items: center;

      h1:first-child {
        font-weight: bold;
      }
    }

    button {
      border-radius: 4px;
      background-color: $magenta;
      border-color: $magenta;
      color: $white;
      font-family: 'Roboto';
      font-size: 10px;

      @media screen and (min-width: 768px) {
        font-size: 12px;
      }
    }
  }

  /* Pestaña Solicitud de la Vista de Cotizaciones Crédito Hipotecario */
  .application-data-downloads {
    margin: 0 16px 16px;
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 8px;
    }

    a {
      color: $light-blue;

      svg {
        margin-left: 8px;
      }
    }
  }

  .ant-tabs {
    .ant-tabs-nav-wrap {
      @media screen and (min-width: 768px) {
        margin: 0 16px;
      }

      button {
        color: $light-blue;
        font-weight: bold;
        font-size: 12px;
        margin-right: 10px;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          margin-right: 32px;
        }
      }

      .ant-tabs-ink-bar {
        background: $light-blue;
      }
    }

    .ant-tabs-content-holder {
      @media screen and (min-width: 768px) {
        margin-top: 16px;
      }
    }

    button.ant-tabs-tab-disabled {
      color: $gray;
    }
  }

  /* Componente ProfileShowMortgageApplicationData */
  .application-show-descriptions.application-credit-data.application-data {
    @media screen and (min-width: 768px) {
      padding: 16px 32px 8px;
    }
  }

  .application-show-descriptions.application-credit-data.application-data.additional-property {
    background-color: $extra-light-gray;
    border: 2px solid $primary-color;
    span {
      color: $primary-color;
    }

    .insurance-description > .ant-descriptions-item-content {
      flex-wrap: wrap;
      .anticon {
        margin-top: 4px;
        margin-right: 6px;
      }
    }

    .insurance-items {
      display: flex;
      align-items: center;
      color: $primary-color;
      justify-content: center;
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .application-show-descriptions.application-credit-data {
    background-color: $primary-color;
    border-radius: 8px;
    padding: 6px 12px;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      position: relative;
      margin: 0 12px 32px;
      padding: 16px 56px 8px 72px;
    }

    .ant-descriptions-title {
      font-family: 'Roboto Slab';
      font-weight: normal !important;
      border-bottom: solid 1px rgba(255, 255, 255, 0.5);
      font-size: 20px;
      padding-bottom: 8px;
      margin-bottom: 12px;

      svg {
        width: 60px;
        height: 67px;
        margin-right: 10px;
      }

      @media screen and (min-width: 768px) {
        font-size: 22px;

        svg {
          position: absolute;
          width: 81px;
          height: 67px;
          top: -10px;
          left: -10px;
        }
      }
    }

    span,
    div {
      color: $white;
      line-height: 18px;
    }

    th,
    td {
      text-align: left !important;
      padding: 5px 0;
      vertical-align: top;
    }

    @media screen and (min-width: 768px) {
      th,
      td {
        text-align: center !important;
        padding: 0;
      }
    }

    td span {
      font-size: 14px;
    }

    .ant-descriptions-item-label {
      text-align: left !important;
      font-weight: bold;

      @media screen and (min-width: 768px) {
        text-align: center !important;
      }
    }

    .ant-descriptions-view {
      margin-bottom: 16px;
    }

    .container-info {
      display: flex;
      justify-content: space-between;

      .container-data1 {
        margin-top: 5px;
        margin-right: 20px;
      }
      .container-data2 {
        margin-top: 5px;
      }
    }
  }

  .application-show-descriptions.application-financial {
    border-radius: 8px;
    background-color: $extra-light-gray;
    margin-bottom: 32px;
    padding: 4px 10px 0px;

    @media screen and (min-width: 768px) {
      padding: 4px 32px 12px;
      margin: 0 12px 32px;
    }

    tr:nth-child(7),
    tr:nth-child(13) {
      border-top: 1px solid #d8d8d8;
    }

    @media screen and (min-width: 768px) {
      tr:nth-child(7),
      tr:nth-child(13) {
        border: none;
      }
      tr:nth-child(5),
      tr:nth-child(9) {
        border-top: 1px solid #d8d8d8;
      }
    }

    tr th {
      text-align: left !important;
      vertical-align: top;
    }

    th {
      padding-bottom: 0;

      span {
        font-size: 11px;
        color: $primary-color;
        font-weight: bold;

        @media screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
    }

    th.descriptions-section-title span {
      font-size: 15px;
      font-weight: bold;
      color: $primary-color;
      font-family: 'Roboto Slab';
      padding-top: 5px;

      @media screen and (min-width: 768px) {
        font-size: 17px;
        padding-top: 12px;
      }
    }

    th.descriptions-section-result:last-child span {
      color: $magenta;
    }

    td {
      span {
        color: $primary-color;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }

    td.descriptions-section-result span {
      color: $magenta;
      font-family: 'Roboto Condensed';
      font-size: 12px;
      font-weight: bold;

      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
  }

  /* Pestaña Solicitud de la Vista de Cotizaciones Crédito Consumo */
  div.gradient-right-pink {
    height: 3px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(232, 22, 135, 1) 100%
    );
  }

  div.gradient-left-pink {
    height: 3px;
    background: rgb(232, 22, 135);
    background: linear-gradient(
      90deg,
      rgba(232, 22, 135, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    margin-bottom: 32px;
  }

  /* Pestaña Solicitud de la Vista de Cotizaciones Crédito Consumo */
  .application-show-descriptions.application-consume-description {
    tr:not(:first-child) {
      border-top: solid 1px $gray;
    }

    td {
      padding: 0;

      @media screen and (min-width: 768px) {
        .ant-descriptions-item-label {
          width: 200px;
        }
      }
    }

    span {
      font-family: 'Roboto';
      font-size: 14px;
      width: 50%;
      color: $primary-color;
    }

    span.ant-descriptions-item-content {
      font-weight: bold;
      justify-content: space-between;
      flex-wrap: wrap;

      @media screen and (min-width: 768px) {
        width: 370px;
      }

      span {
        color: rgba(0, 0, 0, 0.65);
        font-weight: normal;
      }
    }

    .seguros-items {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        width: auto;
      }
    }

    .seguros-descriptions > .ant-descriptions-item-content {
      .anticon {
        margin-right: 6px;
        color: $primary-color;
      }
    }
  }

  /* Sección Ver de la Vista de Cotizaciones Crédito (Pestaña Oferta) */
  .application-show-descriptions.application-answer-description {
    background-color: $extra-light-gray;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      margin: 0 12px 32px;
      padding: 16px 56px 8px 72px;
    }

    .ant-descriptions-title {
      font-family: 'Roboto Slab';
      font-weight: normal !important;
      border-bottom: solid 1px rgba(5, 3, 88, 0.5);
      font-size: 22px;
      padding-bottom: 8px;
      margin-bottom: 12px;
    }

    span,
    div {
      color: $primary-color;
      line-height: 18px;
    }

    th,
    td {
      padding: 0;
      vertical-align: top;
    }

    td span {
      font-size: 14px;
    }

    tr:nth-child(2) {
      td {
        padding-bottom: 16px;
      }
    }

    .ant-descriptions-item-label {
      font-weight: bold;
    }
  }

  /* TODO: VALIDAR A QUÉ COMPONENTE PERTENECE */
  .application-show-table.application-expenses-description {
    margin: 0 12px 24px;
    padding: 16px 56px 16px 72px;
    background-color: $extra-light-gray;
    border-radius: 8px;
    position: relative;

    .ant-table {
      background-color: $extra-light-gray;
    }

    .ant-table-title {
      font-family: 'Roboto Slab';
      font-weight: normal !important;
      font-size: 22px;
      padding: 12px 0;
      margin-bottom: 6px;

      svg {
        position: absolute;
        left: -80px;
        top: -30px;
      }
    }

    span,
    div {
      color: $primary-color;
      line-height: 18px;
      font-family: 'Roboto';
    }

    th,
    td {
      padding: 4px 0;
      vertical-align: top;
      border-bottom: 0;

      span {
        font-size: 14px;
        font-weight: normal;
      }
    }

    thead th {
      font-weight: bold;
      color: $primary-color;
      font-size: 15px;
      border-bottom: solid 1px rgba(82, 87, 90, 0.5);
    }

    tr:first-child td {
      padding-top: 8px;
    }

    tr:nth-last-child(2) td {
      padding-bottom: 8px;
    }

    tr:last-child td {
      padding-top: 12px;
      border-top: solid 1px rgba(82, 87, 90, 0.5);
      font-size: 22px;
      font-weight: bold;
    }
  }
}

/* TODO: VALIDAR A QUÉ COMPONENTE PERTENECE */
#profile-user-col {
  .ant-layout-content {
    background-color: $white-smoke;

    #profile-user-image-wrapper {
      width: 244px;
      height: 244px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 122px;
      }
    }
  }
}

/* TODO: VALIDAR A QUÉ COMPONENTE PERTENECE */
#profile-applications-content {
  height: 330px;
}

/* Sección Noticias de la vista Perfil/Home */
#profile-news-container {
  border-radius: 8px;
  background-color: $white;
  height: auto;
  width: 100%;
  height: 100%;

  .news-card {
    background-color: #eeeef4;
    border-radius: 4px;
    margin: 16px 8px;
    display: flex;
    height: 150px;
    border-radius: 4px;

    img {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 108px;
      object-fit: cover;

      @media screen and (min-width: 992px) {
        width: 25%;
      }
    }

    .news-card-info {
      width: 100%;
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: 768px) {
        padding: 0 24px;
      }

      .date {
        color: $magenta;
        font-weight: bold;
        font-size: 10px;
        margin-bottom: 5px;
      }

      .title {
        color: $primary-color;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
        margin-bottom: 4px;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .subtitle {
        color: #52575a;
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    a {
      background-color: $light-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      svg {
        fill: $white;
        height: 20px;
        width: 20px;
      }
    }
  }
}

/* Sección Educación Financiera de la vista Perfil/Home */
#profile-education-container.ant-layout-content {
  background-color: #eeeef4;
  border-radius: 4px;
  height: 100%;

  .education-card {
    background-color: $white;
    margin: 14px 8px;
    border-radius: 4px;
    height: 80px;
    display: flex;
    justify-content: space-between;

    .education-card-header {
      width: 45%;
      margin: 8px 8px 0px 16px;

      .date {
        font-size: 10px;
        color: $magenta;
        font-weight: bold;
      }

      .title {
        font-size: 14px;
        color: $primary-color;
        font-weight: bold;
        line-height: 16px;
      }
    }

    .education-card-summary {
      width: 45%;
      margin: 24px 8px 12px 8px;
      font-size: 12px;
      color: $gray;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      line-height: 14px;
    }

    .education-card-button-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      a {
        width: 32px;
        height: 32px;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-right-radius: 4px;

        svg {
          fill: $white;
        }
      }
    }
  }
}

/* Sección Perfil/Info al seleccionar la opción Mi Perfil (Info Personal y Financiera) */
#profile-info-title-icons {
  display: flex;
  justify-content: space-between;

  #profile-progress-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    align-items: center;
    color: #4d4f5c;
    font-family: 'Roboto';
    font-weight: normal;
    @media screen and (min-width: 768px) {
      margin-right: 24px;
    }
  }

  .ant-badge {
    background-color: rgba(5, 3, 88, 0.1);
    color: rgb(5, 3, 88);
    padding: 4px 12px;
    border-radius: 16px;
  }
}

/* Pestaña Información Personal */
.personal-info-descriptions {
  .ant-descriptions-row:nth-child(odd) .ant-descriptions-item {
    padding-bottom: 0;

    span {
      color: $gray;
    }
  }

  .ant-descriptions-row:nth-child(even) .ant-descriptions-item span {
    color: $primary-color;
  }

  .ant-descriptions-item {
    font-family: 'Roboto';
    font-size: 14px;
    text-align: start !important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .title span {
    color: $primary-color !important;
    font-weight: bold;
    font-size: 20px;
  }
}

/* Pestaña Información Financiera */
.financial-info-descriptions {
  background-color: $extra-light-gray;
  border-radius: 8px;
  padding: 8px;
  margin: 0 -10px 20px -10px;

  @media screen and (min-width: 768px) {
    padding: 16px 48px 24px;
    margin: 0 -16px 16px;
  }

  .ant-descriptions-title {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
    }

    span {
      font-family: 'Roboto Slab';
      color: $primary-color;
      font-weight: bold;
      font-size: 17px;
      margin-bottom: 5px;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        margin-bottom: 0;
      }
    }
  }

  #profile-info-title-icons span {
    font-size: 12px !important;
    height: 26px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }

  .ant-descriptions-item {
    padding-bottom: 0;
    text-align: start !important;

    .ant-descriptions-item-label {
      vertical-align: top;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      font-family: 'Roboto';
      font-size: 12px;
      color: $primary-color;
      width: auto;

      @media screen and (min-width: 768px) {
        font-size: 13px;
      }
    }

    .ant-descriptions-item-label {
      font-weight: bold;
    }

    .ant-descriptions-item-label::after {
      display: none;
    }
  }

  .limit-text {
    .ant-descriptions-item-label {
      max-width: 80%;
      @media screen and (min-width: 768px) {
        max-width: auto;
      }
    }
  }

  .total {
    font-weight: bold;

    span {
      color: $magenta !important;
    }

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: flex-end;
    }
  }
}

/* Sección al seleccionar Nuevas Cotizaciones */
.profile-application-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .phone-container-left {
    width: auto;
    max-width: 75%;
    word-wrap: break-word;
    word-break: break-word;
  }

  h1 {
    text-transform: capitalize;
    margin-top: 12px !important;
    text-overflow: clip;
  }

  a {
    color: $magenta;
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
    }
  }

  a:hover {
    color: $light-blue;
  }
}

.profile-application-type {
  h1 {
    margin-top: 5px;
    font-size: 11px !important;

    @media screen and (min-width: 768px) {
      font-size: 13px !important;
    }
  }
}

/* Estilos del componente generado cuando no hay Cotizaciones */
#applications-empty {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;

  .title {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: bold;
    color: $primary-color;
    margin-top: 12px;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 16px;
    font-family: 'Roboto';
    color: $gray;
  }
}

.with-shadow,
#profile-wrapper > ul {
  box-shadow: 0px 3px 6px 0px #d1d0d0;
}

.ant-modal-confirm-btns {
  .ant-btn-primary {
    background-color: $light-blue;
    border-color: $light-blue;
  }
}

/* Sección Perfil/Home o Cotizaciones Tabla de Cotizaciones */
.ant-table-wrapper.applications-table {
  thead th {
    background-color: rgba(255, 255, 255, 0);
    font-family: 'Roboto';
    font-weight: 500;
    color: $gray;
    font-size: 11px;

    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }

  td {
    font-family: 'Roboto';
    font-size: 9px;

    .ant-tag {
      padding: 0 3px;
      margin: 0;
      font-size: 7px;
    }

    @media screen and (min-width: 768px) {
      font-size: 14px;

      .ant-tag {
        font-size: 12px;
        padding: 0 7px;
        margin-right: 8px;
      }
    }
  }
}

/* Pestaña Requerimiento de Cotizaciones */
.application-required-changes {
  @media screen and (min-width: 768px) {
    margin-top: -16px;
  }

  p.application-required-changes-section-title {
    color: $gray;
    font-size: 14px;

    @media screen and (min-width: 768px) {
      margin-left: 32px;
    }
  }
}

/* Pestaña Requerimiento de Cotizaciones */
.change-card {
  background-color: $extra-light-gray;
  border-radius: 8px;
  padding: 0px 10px;
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    padding: 16px 32px;
    margin-bottom: 16px;
  }

  .ant-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-col:first-child {
    p {
      color: $primary-color;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }

    p:first-child {
      margin-bottom: -4px;
      color: $magenta;
      font-weight: 500;
      font-size: 12px;

      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .ant-col:nth-child(2) {
    p {
      color: #52575a;
      font-size: 12px;
      margin-bottom: 0;
      line-height: 14px;
    }
  }

  .ant-col:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      background-color: $light-blue;
      border-color: $light-blue;
      border-radius: 8px;
      color: $white;
      font-weight: bold;
      width: 150px;
      height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: 768px) {
        width: 200px;
      }
    }
  }

  .ant-col.previous-card {
    align-items: flex-start;

    p:first-child {
      color: $primary-color;
      font-weight: bold;
      margin-right: 4px;
    }
  }

  .section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .section-urls {
    p {
      margin-bottom: 0;
    }

    li {
      color: $primary-color;
    }

    a {
      color: $light-blue;
    }
  }
}

/* Pertenece a los componentes ProfileShowConsumeApplicationResult y ProfileShowMortgageApplicationResult */
.time-left {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(232, 22, 135, 0.07);
  border: 1px solid $magenta;
  border-radius: 8px;
  position: relative;
  padding: 18px;

  svg {
    position: absolute;
    bottom: 45px;
    left: -30px;
    height: 50px;

    @media screen and (min-width: 768px) {
      left: -25px;
      bottom: auto;
      height: 90px;
    }
  }

  span svg {
    position: static;
    height: auto;
    fill: $magenta;
  }

  p {
    margin: 8px 4px;
    color: $magenta;
    font-family: 'Roboto';

    @media screen and (min-width: 768px) {
      size: 16px;
    }
  }

  p:first-of-type {
    font-weight: bold;
  }
}

/* Pertenece a los componentes ProfileShowConsumeApplicationResult y ProfileShowMortgageApplicationResult */
.content-info-text {
  margin-top: 10px;
  text-align: center;
}

/* Pestaña Oferta de las Cotizaciones */
#main-info {
  display: flex;
  background-color: rgba(66, 145, 255, 0.0666667);
  font-size: 1rem;
  font-family: 'Roboto';
  color: $primary-color;
  border: solid 1px $light-blue;
  margin: 0 0 30px;
  padding: 10px 2px;

  @media screen and (min-width: 768px) {
    margin: 0 12px 30px;
    padding: 14px 37px;
  }

  .time-limit {
    font-size: 11px;

    .bold {
      font-weight: 500;
      padding-right: 10px;
    }

    .remaining {
      padding-left: 10px;
      border-left: solid 1px $primary-color;
    }

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}

/* Información parcial del Modal al crear una Nueva Cotización */
#new-application-modal-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .button-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, $white, $light-gray);
    margin: 8px;
    border-radius: 8px;
    color: $primary-color;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 8px;
    cursor: pointer;
    max-width: 45%;

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }

    svg {
      margin: 16px;
    }
  }

  .button-card.with-margin {
    text-align: center;
    height: 145px;
    margin-top: 25px;
  }
}

.ant-modal-title {
  font-family: 'Roboto' !important;
  color: $primary-color !important;
  font-size: 18px !important;
}

/* Modal que se encuentra en el componente ProfileShowConsumeApplicationResult */
div.application-result-modal {
  h3 {
    color: $primary-color;
    font-size: 18px;
  }
  p,
  label {
    color: $primary-color;
    font-size: 13px;
  }

  textarea {
    resize: none;
  }
}

/* Pestaña Chat de las Cotizaciones */
#chat-container {
  min-height: 600px;
  max-height: 600px;
  margin: 5px;
  padding: 20px;
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.21);

  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 400;
    color: #4d4f5c;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    border-bottom: solid 1px #52575a22;
  }

  .chat {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
    }

    .bank,
    .user {
      width: 100%;
      .message {
        width: 50%;
        @media screen and (min-width: 768px) {
          width: 25%;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 20px;

        p {
          width: 100%;
          max-width: 300px;
          min-width: 120px;
          margin: 0;
          padding: 10px 15px;
        }
        span {
          font-size: 0.7rem;
        }
      }
    }

    .bank {
      display: flex;
      justify-content: flex-end;
      .message {
        p {
          background-color: $light-blue;
          border-radius: 28px 28px 0px 28px;
          -moz-border-radius: 28px 28px 0px 28px;
          -webkit-border-radius: 28px 28px 0px 28px;
          color: white;
        }
      }
    }
    .user {
      display: flex;
      justify-content: flex-start;
      .message {
        p {
          background-color: #edf0f5;
          border-radius: 28px 28px 28px 0px;
          -moz-border-radius: 28px 28px 28px 0px;
          -webkit-border-radius: 28px 28px 28px 0px;
          color: #4d4f5c;
        }
      }
    }
  }

  .send {
    border-top: solid 1px #52575a22;
    padding-top: 15px;
    .ant-btn-primary,
    .ant-input-group-addon,
    .ant-btn,
    .ant-input-search-button {
      background-color: transparent;
      border: none;
      -webkit-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
      -moz-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
    }
    .ant-input {
      border: none;
      -webkit-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
      -moz-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
    }
  }
}
