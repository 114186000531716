.ant-layout {
  width: 100%;
  background: $white-smoke !important;
  z-index: 0;
  position: relative;
}

#root > .ant-layout {
  min-width: 100%;
}

.ant-layout-content.app-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 312px);
  margin: 80px 24px 12px 24px;
}

/* Header */
.header.ant-layout-header {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: fixed;
  min-height: 64px;
  width: 100%;
  padding: 0 40px;

  ul {
    background-color: transparent;
    width: 100%;
    max-width: 1200px;
  }

  li.ant-menu-item {
    display: flex;
    justify-content: center;
  }

  transition: all 0.3s ease;
  background-color: $primary-color;

  a,
  span {
    color: $white;
  }

  a:hover {
    color: $light-blue !important;
  }

  .navbar-logo path {
    fill: $white;
  }

  button > span {
    color: $white;
  }
}

.header.ant-layout-header.scrolled-home-header {
  background-color: $white;
  a,
  span {
    color: $primary-color;
  }

  a:hover {
    color: $light-blue;
  }

  .navbar-logo path {
    fill: $primary-color;
  }

  button > span {
    color: $white;
  }
}

.header.ant-layout-header.home-header {
  background-color: $primary-color;

  @media screen and (min-width: 992px) {
    background-color: transparent;
  }

  ul {
    background-color: transparent;
  }

  a:hover {
    color: $light-blue !important;
  }

  transition: all 0.3s ease;
}

/* Footer */
#footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary-color;
  color: $white;
  padding: 16px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    height: 220px;
    padding: 16px calc(50vw - 600px);
  }

  #footer-logo {
    height: 42px;
    width: 150px;

    @media screen and (min-width: 768px) {
      height: 90px;
      width: 250px;
      top: -10px;
      position: relative;
    }
  }

  #footer-data {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-top: 0;
      width: auto;
    }

    .footer-data-section {
      display: flex;
      flex-direction: column;
      text-align: center;

      @media screen and (min-width: 768px) {
        text-align: start;
        margin: 0 24px;
      }

      .footer-data-section-title {
        font-family: 'Roboto Slab';
        font-weight: bold;
        opacity: 0.85;
        font-size: 17px;

        @media screen and (min-width: 768px) {
          padding: 0;
          margin-bottom: 16px;
          font-size: 20px;
        }
      }

      .footer-data-section-item {
        font-family: 'Roboto';
        opacity: 0.7;
        margin-bottom: 10px;
        font-size: 13px;

        a {
          font-family: 'Roboto';
          margin-bottom: 4px;
          font-size: 13px;
          color: $white;
        }

        @media screen and (min-width: 768px) {
          font-size: 16px;
          margin-bottom: 4px;
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
}

/* Enlace Iniciar Sesión */
.login-nav-div {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  color: $primary-color;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 300px;
  padding: 16px;
  width: 320px;
  height: 300px;
  top: 80px;
  right: calc(max(60px, 50vw - 590px));

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
    }
    a {
      height: 24px;
      line-height: 12px;
      font-size: 12px;
      color: $light-blue !important;
    }
  }

  form {
    p {
      margin-bottom: 0;
      color: $primary-color;
      font-size: 12px;
    }

    input {
      color: $primary-color;
    }

    p.center-text {
      text-align: center;
      margin-bottom: 24px;
    }

    div.ant-row {
      margin-bottom: 8px;
      height: 32px;
    }

    button {
      width: 100%;
      height: 28px;
      border-color: $primary-color;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        display: flex;
        align-items: center;
      }

      span {
        font-size: 12px;
      }
    }

    button:not(.ant-btn-primary) span {
      color: $primary-color !important;
    }
  }
}
