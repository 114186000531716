$side-margin: calc(max(32px, 50vw - 640px));
$side-margin-plus: calc(max(64px, 50vw - 640px));

/* Contenedor Principal */
#home-wrapper {
  margin: -122px -24px -12px -24px;
  display: flex;
  flex-direction: column;
  width: calc(100% + 48px);
}

/* Sección Texto Inicial Home */
#landing-container {
  position: relative;
  width: 100%;
  min-height: calc(max(650px, 50vw));
  background-image: linear-gradient(
      rgba(1, 0, 58, 0.5),
      rgba(5, 3, 88, 0.3),
      rgba(171, 184, 195, 1)
    ),
    url('./images/landing.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  align-content: center;
  align-self: center;

  @media screen and (min-width: 768px) {
    min-height: calc(max(700px, 50vw));
  }

  .section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin: 35% 0 0 0;

    @media screen and (min-width: 576px) {
      margin: 25% 0 0 0;
    }

    @media screen and (min-width: 768px) {
      margin: 17% 0 0 0;
    }

    #landing-title {
      width: 80%;
      font-family: 'Roboto Slab';
      font-weight: bold;
      color: $white;
      font-size: 35px;
      text-align: center;
      line-height: 40px;
      margin-bottom: 12px;

      @media screen and (min-width: 768px) {
        width: 720px;
        font-size: 72px;
        line-height: 80px;
        margin-bottom: 24px;
      }
    }

    #landing-subtitle {
      width: 80%;
      font-family: 'Roboto';
      font-weight: 500;
      color: $white;
      font-size: 15px;
      text-align: center;

      @media screen and (min-width: 768px) {
        width: 720px;
        font-size: 24px;
      }
    }

    #new-application-modal-content {
      width: 60%;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;

      @media screen and (min-width: 768px) {
        margin-top: 0px;
        width: 860px;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      .button-card {
        min-width: 35%;
        max-width: 45%;
        height: 125px;
        text-align: center;
        border-bottom: 8px solid $magenta;

        @media screen and (min-width: 768px) {
          height: auto;
          min-width: auto;
          max-width: 300px;
          margin-left: 25px;
        }

        svg {
          height: 50px;
          width: 30%;

          @media screen and (min-width: 768px) {
            height: auto;
            width: auto;
          }
        }

        p {
          margin: 0;
          font-size: 11px;

          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }

    @media screen and (min-width: 1900px) {
      #landing-title {
        width: 720px;
        line-height: 90px;
      }

      #landing-subtitle {
        margin-bottom: 10vh;
      }
    }
  }
}

#landing-extra-container {
  text-align: center;
  padding: 25px;

  #landing-extra-info-box {
    max-width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    .landing-extra-logo {
      margin: 15px auto;
      width: 25%;
      height: auto;
    }

    @media screen and (min-width: 768px) {
      max-width: 65%;
    }

    h1 {
      color: $primary-color;

      @media screen and (min-width: 768px) {
        font-size: 35px;
      }
    }

    p {
      color: $white;
      font-weight: 900;
      font-size: 16px;
    }
  }

  #landing-extra-video-box {
    width: 90%;
    padding: 5px;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      width: 70%;
    }

    video {
      border: 3px solid $magenta;
      border-radius: 8px;
    }
  }
}

/* Sección Qué Hacemos */
#container-background {
  background-image: linear-gradient(
      rgba(221, 232, 241, 1),
      rgba(221, 232, 241, 0.6),
      rgba(255, 255, 255, 1)
    ),
    url('./images/what_we_do.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

#what-we-do-container {
  position: relative;
  width: 100%;
  height: 380px;
  background: linear-gradient($gray, $light-gray);

  @media screen and (min-width: 768px) {
    height: 310px;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;

    @media screen and (min-width: 768px) {
      margin: 0px $side-margin-plus;
      width: -webkit-fill-available;
    }

    #what-we-do-title {
      font-family: 'Roboto Slab';
      font-weight: bold;
      color: $primary-color;
      font-size: 35px;
      margin-bottom: 24px;

      @media screen and (min-width: 768px) {
        font-size: 48px;
        margin-bottom: 25px;
      }
    }

    #what-we-do-box {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 8px;
      border: 1px solid $light-blue;
      margin: 0 auto;

      @media screen and (min-width: 576px) {
        width: 90%;
      }

      @media screen and (min-width: 768px) {
        width: auto;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-evenly;
      }

      .what-we-do-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 45%;
        min-height: 120px;
        height: auto;
        margin: 0 0 15px 0;

        @media screen and (min-width: 576px) {
          width: 40%;
        }

        @media screen and (min-width: 768px) {
          justify-content: flex-start;
          padding: 16px;
          height: 180px;
          width: 25%;
          margin: 0;
        }

        svg {
          height: 42px;
          width: 100%;
        }

        @media screen and (min-width: 768px) {
          svg {
            height: 72px;
            width: 100%;
          }
        }

        @media screen and (min-width: 992px) {
          svg {
            height: 92px;
            width: 100%;
          }
        }

        p {
          margin-top: 10px;
          font-family: 'Roboto Condensed';
          color: $primary-color;
          font-weight: bold;
          text-align: center;
          font-size: 13px;
          margin-bottom: 0;
          line-height: 18px;
        }

        @media screen and (min-width: 768px) {
          p {
            margin-top: 18px;
            font-size: 16px;
            line-height: 20px;
          }
        }

        @media screen and (min-width: 992px) {
          p {
            margin-top: 18px;
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

/* Sección Simula tu Crédito */
#calculators-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 20px;

  .section-title {
    font-family: 'Roboto Slab';
    font-size: 30px;
    text-align: center;
    margin-bottom: 24px;
  }

  @media screen and (min-width: 992px) {
    padding: 120px $side-margin-plus;
    height: 710px;

    .section-title {
      font-size: 48px;
      width: 100%;
      margin-bottom: 34px;
    }
  }
}

#calculator-container {
  display: flex;
  background: linear-gradient(
    rgba(171, 184, 195, 0.7),
    rgba(221, 232, 241, 0.8),
    rgba(221, 232, 241, 1)
  );
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
  height: auto;

  @media screen and (min-width: 992px) {
    height: 336px;
  }

  p {
    color: $white;
    font-family: 'Roboto Condensed';
    font-weight: bold;
    letter-spacing: 0.1vw;
    margin-bottom: 0;
  }

  #calculator-center-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    padding: 4px 12px 0 32px;

    @media screen and (min-width: 992px) {
      padding: 16px 16px 0 40px;
    }

    .calculator-title {
      color: $primary-color;
      align-self: flex-start;
      font-size: 14px;

      @media screen and (min-width: 992px) {
        font-size: 20px;
      }
    }

    #calculator-house-wrapper {
      position: relative;
      height: 115px;
      width: 140px;
      margin: 8px 24px 24px;

      @media screen and (min-width: 992px) {
        height: 128px;
        width: 220px;
      }

      .react-reveal {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }

    // Slider
    #calculator-slider-legends {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 140%;

      p {
        font-size: 10px;
        color: $dark-gray;
        flex-grow: 1;
        text-align: center;
        letter-spacing: 1px;
      }

      #current-slider-value {
        font-size: 13px;
        color: $magenta;
        letter-spacing: 1px;

        @media screen and (min-width: 992px) {
          font-size: 16px;
        }
      }
    }

    .ant-slider {
      width: 100%;
      margin-top: 0;
      margin-bottom: 16px;

      .ant-slider-track {
        background-color: $magenta;
      }

      .ant-slider-handle {
        border-color: $white;
        cursor: ew-resize;
      }
    }
  }

  #calculator-left-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    width: 50%;

    @media screen and (min-width: 992px) {
      padding: 0 32px;
    }

    #calculator-pie-inputs {
      display: flex;

      .ant-form-item-control-input-content,
      .ant-row,
      .ant-col {
        width: 100% !important;

        .ant-input-number {
          width: 50% !important;
        }

        .ant-input-number:nth-child(2) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-bottom: 0;
        }

        .ant-input-number:nth-child(3) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        }
      }
    }

    p {
      color: $primary-color;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: normal;

      @media screen and (min-width: 992px) {
        font-size: 14px;
      }
    }

    .ant-input-number {
      color: $primary-color;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 4px;
      border-color: $primary-color;
      width: 100%;
      font-size: 13px;

      @media screen and (min-width: 576px) {
        font-size: 16px;
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }

    .ant-input-number input::selection {
      background: $light-blue;
    }

    .ant-input-number:hover,
    .ant-input-number-focused {
      border-color: $magenta;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    p.result {
      font-size: 16px;
      font-weight: bold;
      color: $magenta;
      text-align: center;
      margin-top: 0.5px;

      @media screen and (min-width: 992px) {
        margin-top: -12px;
        font-size: 28px;
        text-align: start;
        margin-top: -12px;
      }
    }

    p:last-child {
      font-size: 10px;
      text-align: center;

      @media screen and (min-width: 992px) {
        text-align: start;
      }
    }

    p#dividendo-label {
      color: $magenta;
      text-align: center;

      @media screen and (min-width: 992px) {
        text-align: start;
      }
    }
  }
  .consume-calculator {
    .ant-form-item {
      margin-bottom: 24px !important;
    }
    .gray {
      color: $dark-gray !important;
    }
  }
}

/* Sección Actualidad */
#container-background-2 {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(221, 232, 241, 0.7),
      rgba(171, 184, 195, 0.7),
      rgba(171, 184, 195, 1)
    ),
    url('./images/interestAndFinancial.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

#interest-data-container {
  display: flex;
  flex-direction: column;

  .section-phone-title {
    width: auto;
    color: $primary-color;
    margin: 15px 0;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    font-family: 'Roboto Slab';
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    height: 670px;

    .section-phone-title {
      display: none;
    }
  }

  #actualidad-container {
    order: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;

    .section-title {
      display: none;
    }

    @media screen and (min-width: 992px) {
      order: 1;
      justify-content: space-between;
      align-items: flex-start;
      width: 840px;

      .section-title {
        width: 100%;
        text-align: end;
        display: inline;
        color: $primary-color;
        font-size: 48px;
      }

      .section-subtitle {
        font-size: 16px;
      }
    }

    #actualidad-charts-container {
      display: none;

      @media screen and (min-width: 992px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;
      }
    }

    .section-subtitle {
      margin-bottom: 0;
    }
  }

  #trade-data-preview {
    align-self: center;
    width: 90%;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      margin-bottom: 32px;
    }

    #trade-data-content {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-items: center;
      background-color: $light-gray;
      border-radius: 8px;
      border: 2px solid $magenta;
      margin-top: 8px;
      height: 115px;

      @media screen and (min-width: 768px) {
        height: 80px;
      }

      .ant-statistic {
        font-family: 'Roboto';
        width: 30%;
        text-align: center;
        line-height: 1rem;

        @media (min-width: 576px) {
          width: 20%;
          line-height: inherit;
          border-left: solid 1px $primary-color;
        }

        .ant-statistic-content {
          color: $primary-color;
        }

        .ant-statistic-title {
          font-size: 12px;
          color: $primary-color;

          @media screen and (min-width: 576px) {
            font-size: 16px;
            margin-bottom: 0;
          }
        }

        .ant-statistic-content-value-int,
        .ant-statistic-content-prefix {
          font-size: 14px;
          font-weight: bold;

          @media screen and (min-width: 576px) {
            font-size: 24px;
          }
        }

        .ant-statistic-content-value-decimal {
          font-size: 11px;

          @media screen and (min-width: 576px) {
            font-size: 14px;
          }
        }
      }

      .ant-statistic:first-child {
        border-left: none;
      }
    }
  }

  #news-container {
    order: 1;
    width: 90%;
    align-self: center;
    height: auto;
    border-radius: 8px;
    border: 1px solid $magenta;
    background-color: rgba($color: $azulino, $alpha: 1);
    padding: 16px;
    margin-bottom: 10px;

    @media (min-width: 1200px) {
      order: 2;
      width: 400px;
      align-self: flex-start;
      margin-bottom: 0;
      margin-top: 55px;
    }

    .ant-list-footer {
      padding: 0;
    }

    .news-card {
      background-color: $white-smoke;
      margin: 0 0 12px;
      height: 145px;
      display: flex;
      position: relative;

      @media screen and (min-width: 768px) {
        height: 126px;
      }

      img {
        width: 120px;
        object-fit: cover;

        @media screen and (min-width: 768px) {
          width: 112px;
        }
      }

      a {
        background-color: $primary-color;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
          fill: $white;
        }
      }

      .news-card-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        overflow: auto;

        @media screen and (min-width: 768px) {
          padding: 0 16px;
        }

        p {
          line-height: 1.2vw;
          margin: 0;
        }

        .date {
          color: $magenta;
          font-weight: bold;
          font-size: 10px;
          margin-bottom: 6px;
        }

        .title {
          color: $primary-color;
          font-weight: bold;
          margin-bottom: 8px;
          line-height: 14px;
          font-size: 12px;

          @media screen and (min-width: 768px) {
            line-height: 16px;
            font-size: 14px;
          }
        }

        .subtitle {
          font-size: 12px;
          line-height: 14px;
          color: $gray;
          width: 85%;
          max-height: 42px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

/* Sección Educación Financera Artículo */
#financial-article {
  width: 90%;
  font-family: 'Roboto';
  margin-top: 10px;

  .markdown-container {
    .aligncenter {
      img {
        height: 65% !important;
        width: 65% !important;
      }
    }

    .video_container {
      iframe {
        height: 65% !important;
        width: 65% !important;
      }
    }
  }

  .markdown-container > p {
    text-align: justify;
  }

  @media screen and (min-width: 768px) {
    width: 60%;

    .markdown-container {
      .aligncenter {
        img {
          height: 250px !important;
          width: 400px !important;
        }
      }

      .video_container {
        iframe {
          height: 300px !important;
          width: 500px !important;
        }
      }
    }
  }

  // TODO: VALIDAR
  h1,
  h2,
  h3 {
    color: $primary-color;
    margin-top: 20px;
  }

  table {
    border: solid 1px $light-gray;
    border-collapse: collapse;
    border-spacing: 0;
  }

  table thead th {
    background-color: $light-gray;
    border: solid 1px $light-green;
    color: $primary-color;
    padding: 15px;
    text-align: left;
    text-shadow: 1px 1px 1px $white;
  }

  table tbody td {
    border: solid 1px $light-gray;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px $white;
  }
}

#financial-education-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 15px 0;

  @media screen and (min-width: 768px) {
    height: 680px;
    padding: 32px $side-margin-plus;
  }

  #financial-education-title {
    font-family: 'Roboto Slab';
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 0;
    text-align: center;
    font-size: 26px;

    @media (min-width: 768px) {
      font-size: 48px;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  #financial-education-subtitle {
    font-family: 'Roboto';
    color: $primary-color;
    margin-bottom: 0;
    width: 632px;
    text-align: end;
    font-size: 16px;
  }

  // Carousel Properties
  .ant-carousel {
    width: 90%;
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      width: -webkit-fill-available;
      margin-top: 32px;
    }

    .slick-slider {
      display: flex;
      align-items: center;
    }

    .slick-track {
      height: 400px;

      @media screen and (min-width: 768px) {
        height: 500px;
      }
    }

    .slick-dots {
      li {
        height: 1vw;
        width: 16px;
        margin: 0.2vw;
      }

      // Inactive
      button {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background-color: #0403584b;
      }

      .slick-active > button {
        // Opacity in primary
        background-color: #040358;
      }
    }
  }

  #carousel-div {
    display: flex !important;
    flex-direction: row;
    justify-content: space-evenly;

    #finantial-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 345px;
      width: 250px;

      @media screen and (min-width: 768px) {
        height: 425px;
        width: 300px;
        padding-bottom: 32px;
      }

      #img-div {
        border-radius: 8px;
        border: 2px solid $magenta;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 225px;
        min-height: 130px;
        z-index: 10;

        @media screen and (min-width: 768px) {
          width: 280px;
          min-height: 160px;
        }
      }

      #info-div {
        position: relative;
        border-radius: 8px;
        background: linear-gradient(
          rgba(221, 232, 241, 0.5),
          rgba(255, 255, 255, 0.4)
        );
        top: -48px;
        padding: 64px 40px 16px;
        margin: auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 260px;

        @media screen and (min-width: 768px) {
          min-height: 312px;
        }

        #summary {
          height: 80px;
          overflow: hidden;
          font-family: 'Roboto';
          color: $dark-blue;
          font-size: 10px;

          @media screen and (min-width: 768px) {
            flex-grow: 4;
            height: 8vw;
            font-size: 12px;
          }
        }

        #date {
          font-family: 'Roboto';
          color: $magenta;
          margin-bottom: 0px;
          font-size: 10px;
        }

        #title {
          font-family: 'Roboto Slab';
          color: $light-blue;
          font-size: 12px;
          margin-bottom: 1px;

          @media screen and (min-width: 768px) {
            margin-bottom: 4px;
            font-size: 14px;
          }
        }

        a {
          font-family: 'Roboto';
          font-weight: bold;
          text-decoration: underline;
          color: $primary-color;
          position: relative;
        }
      }
    }

    .slick-dots > li:not(.slick-active) > button {
      opacity: 1;
    }
  }
}

/* Sección Contáctanos */
#container-background-3 {
  background-image: linear-gradient(
      rgba(171, 184, 195, 1),
      rgba(171, 184, 195, 0.6),
      rgba(5, 3, 88, 0.4)
    ),
    url('./images/contactUs.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

#contact-us-container {
  padding: 0 $side-margin-plus 32px;
  background-size: cover;
  position: relative;
  height: auto;

  @media (min-width: 992px) {
    padding: 30px $side-margin-plus 32px;
    height: 550px;
  }

  #contact-us-title {
    font-family: 'Roboto Slab';
    font-weight: bold;
    color: $primary-color;
    opacity: 0.7;
    margin-bottom: 0;
    position: relative;
    font-size: 45px;

    @media (min-width: 992px) {
      font-size: 90px;
      margin-bottom: 40px;
    }
  }

  #contact-us-content {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
    }

    #contact-us-form {
      @media screen and (min-width: 992px) {
        width: 600px;
      }

      .contact-us-form-row {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 992px) {
          flex-direction: row;

          .contact-us-form-col:nth-child(2) {
            margin-left: 16px;
          }
        }

        .contact-us-form-col {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          p {
            font-family: 'Roboto';
            color: $primary-color;
            margin-bottom: 4px;
          }

          input,
          textarea {
            border-color: $light-gray;
          }
        }
      }

      .contact-us-container-button {
        display: flex;
        justify-content: flex-end;
      }

      button {
        margin-top: 8px;
        background-color: $magenta;
        border-color: $magenta;
        font-weight: bold;
        letter-spacing: 4px;
        width: 160px;
        height: 45px;
      }
    }

    #contact-us-info {
      display: none;
      align-self: center;

      @media screen and (min-width: 992px) {
        display: block;
        align-self: flex-start;
      }

      #contact-us-info-title {
        color: $primary-color;
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 18px;
      }

      .contact-us-info-row {
        display: flex;
        color: $primary-color;
        font-family: 'Roboto';
        align-items: center;

        p {
          margin: 8px;
          font-size: 16px;
        }
      }
    }
  }
}

/* Componente Sponsors y Veneture */
#sponsors-section {
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 32px;
    width: auto;
  }

  .veneture-logo {
    height: 65px;
    margin-left: 60px;
    width: auto;
  }
}

/* Ajustes Adicionales */
.custom-tooltip {
  border: 2px solid gray;
  padding: 0.5em;
  background-color: $white;

  .value {
    color: $magenta-blue;
  }
}

.graph-checkbox-div {
  margin-left: 15.5em;
}

.ant-col.news-col {
  background-color: $white;
  border-radius: 0.8vw;
}

.background-image {
  position: relative;
  z-index: -1;
  width: 100%;
}

.section-title {
  font-family: 'Roboto Slab';
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 0;
}

.section-subtitle {
  font-family: 'Roboto';
  color: $primary-color;
}

.section-content {
  top: 0;
  position: absolute;
}
