/* Iniciar Sesión */
#sign-in-container {
  min-height: 100vh;
  font-family: 'Roboto';
  background-image: linear-gradient(rgba(1, 0, 58, 0.6), rgba(1, 0, 58, 0.6)),
    url('./images/signInImage.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  @media screen and (min-width: 992px) {
    background-image: none;
    #side-image-wrapper {
      background-color: rgba(1, 0, 58, 0.6);
      height: 100vh;
      overflow: hidden;
      // Test
      img {
        background-color: rgba(5, 3, 88, 0.6);
        position: relative;
        z-index: -1;
        width: 100%;
      }
    }
  }

  .sign-in-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .mini-phone-background {
      align-self: center;

      background-color: $white-smoke;
      padding-top: 15px;
      border-radius: 5px;

      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }

    .flit-logo {
      display: flex;
      justify-content: center;
      align-self: center;

      svg {
        height: 50px;
      }

      @media screen and (min-width: 992px) {
        svg {
          height: auto;
        }
      }
    }

    .sign-in-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 8% 0;

      .ant-tabs {
        width: 250px;

        @media screen and (min-width: 768px) {
          width: 300px;
        }

        @media screen and (min-width: 992px) {
          width: 400px;
        }
      }

      .ant-input {
        border-radius: 4px;
        border: 1px solid $dark-blue;
        background-color: $white;
      }

      .link {
        float: right;

        a {
          color: $dark-blue;
        }
      }
    }

    .login-form-botton {
      margin-top: 48px;
      background-color: $dark-blue;
      border-color: $dark-blue;
    }
  }
}

.google-button {
  background-color: $white !important;
  border-color: $dark-blue !important;
  color: $dark-blue !important;
}

/* Sección de Recuperación de Password */
.password-forget-container {
  width: 100%;
  min-height: calc(100vh - 312px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Roboto';
    color: $primary-color;
    text-align: center;
    font-size: 30px;
  }

  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 5px;
  }

  .forgot-password-form {
    display: flex;
    flex-direction: column;
    button.ant-btn-primary {
      margin-top: 5px;
    }
    .ant-row.ant-form-item {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: 45px;
    }

    .ant-form-item-control-input {
      .ant-input {
        height: 40px;
        width: 300px;
      }
    }

    .forgot-password-form {
      button.ant-btn-primary {
        font-size: 18px;
        height: 40px;
      }
    }
  }
}
