.article-preview-container {
  width: 40em;
  padding: 1em;

  h2 {
    color: $primary-color;
  }

  img {
    width: 14em;
  }

  li:hover {
    width: 101%;
  }
}

#articles-content {
  width: 60%;
  margin: 2rem auto;
  // margin-bottom: 10rem;
  // margin: 1rem 3rem;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  p {
    text-align: justify;
    text-justify: inter-word;
  }
  h1 {
    font-size: 1.5rem;
    color: $primary-color;
    font-weight: normal;
  }

  .summary {
  }
  .date {
    font-weight: 500;
  }

  img.link {
    max-height: 400px;
    width: auto;
    height: auto;
  }

  .link {
    margin-top: 20px;
    font-size: 1.1rem;
  }

  .content {
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 20px;
  }
}
