.container-card-bank {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;

  .card-bank {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    min-height: 140px;
    max-height: 140px;
    max-width: 16%;

    border: 2px solid $primary-color;
    border-radius: 5px;
    flex: 15%;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 5px;

    svg {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }

    .ant-radio-wrapper {
      .ant-radio-inner {
        border: 2px solid $magenta;
        width: 18px;
        height: 18px;
      }
    }
  }
}
