/* Estilos compartidos de ambos menus */
.menu {
  display: flex;
  flex-direction: column;
  position: relative;

  li > a.nav-text,
  span.small-span,
  span.anticon {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: $white;
    transition: all 0.3s ease;
    margin: 0px;
    text-transform: uppercase;
  }

  li:last-child {
    margin-bottom: 20px !important;
  }

  button.small-button {
    background-color: $light-blue;
    border-color: $light-blue;
    padding-left: 10px;
    padding-right: 10px;
  }

  button.small-button:hover {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .ant-menu-submenu-title {
    padding-right: 0;

    span.small-span {
      margin: 0;
      display: flex;
      align-items: center;

      span {
        margin: 0 0 0 8px;
      }
    }
  }

  .anticon-menu {
    display: block;
  }

  @media screen and (min-width: 992px) {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;

    li:last-child {
      margin-bottom: auto !important;
    }

    .anticon-menu {
      display: none;
    }

    .menu-item-logo {
      position: absolute !important;
      left: -20px;
      // left: 0;
      height: 100%;
      padding: 0;

      .logo-wrapper {
        display: flex;
        width: 104px;
        align-items: center;
        position: relative;
        margin-bottom: 10%;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

/* Ajustes Adicionales */
.anticon-down {
  margin-left: 12px;
}

.navBar-button-right {
  align-self: center;
  margin-right: 0;
  margin-left: auto;
  height: 64px;
}

span.anticon.anticon-menu svg {
  width: 2em;
  height: 2em;
  color: $white;
}

/* Contenedor para dispositivos móviles */
.phone-container {
  width: 100%;
  background-color: $primary-color;
  height: auto;
  position: absolute;
  top: 0;

  a.nav-text {
    color: $white !important;
  }

  .first-elements {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    a.logo-vertical svg {
      height: 35px;
      width: auto;
      margin-top: 15px;
    }

    button.small-button {
      background-color: $light-blue;
      border-color: $light-blue;
    }

    button.small-button:hover {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  li.vertical-submenu {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: rgba($color: #000, $alpha: 0.2);

    a {
      color: $light-blue !important;
    }
  }
}
